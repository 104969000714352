<template>
    <router-link :to="url" custom>
        <a class="game-item__empty">
            <div class="game-item__empty-body">
                <div class="game-item__empty-wrapper">
                    <div class="game-item__empty-container">
                        <FeIcon
                            :icon="FeIconConfig.icons.outline.search"
                            class="game-item__empty-img"
                        />
                        <div class="game-item__empty-text">
                            {{ $t("GAME_ITEM.VIEW_ALL") }}
                        </div>
                    </div>
                    <FeButtonNew
                        v-if="featureFlags.enableNewUIComponents"
                        full-width
                        :theme="buttonVariant"
                        class="game-item__empty-action"
                        @click.stop="$router.push(url)"
                    >
                        {{ $t("GAME_ITEM.VIEW_ALL_BUTTON") }}
                    </FeButtonNew>
                    <FeButton
                        v-else
                        :full-width="true"
                        :theme="sizeButton"
                        class="game-item__empty-action"
                        @click.stop="$router.push(url)"
                    >
                        {{ $t("GAME_ITEM.VIEW_ALL_BUTTON") }}
                    </FeButton>
                </div>
            </div>
        </a>
    </router-link>
</template>

<script>
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import { mapState } from "pinia";
import { useRootStore } from "@store/root";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "GameItemEmpty",

    components: {
        FeIcon,
        FeButton,
        FeButtonNew,
    },

    props: {
        url: {
            type: Object,
        },
    },

    data() {
        return {
            FeIconConfig,
            FeButtonConfig,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        sizeButton() {
            return FeButtonConfig.themes.SecondarySmall;
        },
        buttonVariant() {
            return this.isMobile ? themes.SecondarySmall : themes.SecondaryMedium;
        },
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
