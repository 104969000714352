<template>
    <div v-if="currentLevel.id" class="level-progress">
        <div class="level-progress__info">
            <span>{{ currentLevel.name }}</span>
            <span class="level-progress__info-value">{{ progress }} / {{ levelMaxXp }} cp</span>
            <span v-if="nextLevel">{{ nextLevel.name }}</span>
        </div>
        <progress-bar class="level-progress__progress-bar" :progress="progress / levelMaxXp * 100" />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUserStatuses } from "@store/user/userStatuses";
import ProgressBar from "@components/ProgressBar/ProgressBar.vue";
import { useLevelsStore } from "@store/levels/levelsStore";
import { useStatusCompPointsStore } from "@store/compPoints/statusCompPointsStore";
import { computed } from "vue";

const { getUserLevelInfo: currentLevel } = storeToRefs(useUserStatuses());
const { getCompPoints: userCompPoints } = storeToRefs(useStatusCompPointsStore());
const { getLevelsById } = useLevelsStore();


const nextLevel = computed(() => {
    const [ code, number ] = currentLevel.value.id.split("_");
    return getLevelsById(`${ code }_${ Number(number) + 1 }`);
});

const condition = computed(() => {
    const conditions = currentLevel.value?.conditions;
    return conditions ? conditions[0] : null;
});

const levelMaxXp = computed(() => {
    return condition.value && condition.value.persistent_comp_points.max;
});

const progress = computed(() => {
    return (userCompPoints.value?.persistent && userCompPoints.value.persistent.points) || 0;
});
</script>

<style lang="scss" src="./style.scss"></style>
