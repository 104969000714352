<template>
    <div
        class="bookmark__list"
        :class="[ {'bookmark__list--logout': !isLogged} ]"
    >
        <VipAdventuresBookmark class="bookmark__item"/>

        <QuestBookmark
            v-for="quest in questsActiveLists"
            :key="quest.id"
            :quest-data="quest"
            class="bookmark__item"
        />

        <SantaVegasBookmark class="bookmark__item"/>

        <LootboxBookmark class="bookmark__item"/>

        <SupportWidgetButton v-if="!isMobile"/>
    </div>
</template>

<script setup lang="ts">
import { STATUS_PROMO } from "@store/tournaments/constants";
import { routeNames } from "@router/routeNames";

import { computed } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import { useCommon } from "@store/common";
import { useQuestStore } from "@store/quest/questStore";

import QuestBookmark from "@modules/Quest/components/QuestBookmark.vue";
import VipAdventuresBookmark from "@modules/VipAdventures/VipAdventuresBookmark.vue";
import LootboxBookmark from "@components/LootBox/LootboxBookmark.vue";
import SupportWidgetButton from "@modules/Support/SupportWidgetButton.vue";
import SantaVegasBookmark from "@views/SantaVegas/SantaVegasBookmark.vue";
import { useUserInfo } from "@store/user/userInfo";

const $route = useRoute();
const { getIsLogged } = storeToRefs(useUserInfo());

const { isMobile } = storeToRefs(useCommon());
const { getQuestsList } = storeToRefs(useQuestStore());

const isLogged = computed(() => getIsLogged.value);

const questsActiveLists = computed(() => {
    if (routeNames.questPage !== $route.name) {
        return getQuestsList.value.filter((item) => item.status === STATUS_PROMO.ACTIVE);
    }
    return [];
});
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.bookmark {
    &__list {
        position: fixed;
        right: 0;
        width: 4rem;
        z-index: 10000;
        bottom: 5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        @include media(XM) {
            width: 6rem;
            gap: 1.5rem;
        }

        padding-bottom: env(safe-area-inset-bottom);

        &#{&}--logout {
            bottom: 9rem;

            @include media(M) {
                bottom: 5rem;
            }

            padding-bottom: env(safe-area-inset-bottom);
        }
    }
}
</style>
