import { computed, ref } from "vue";

import {
    addPlayerToGroup, confirmEmailResendReg, confirmPlayerReq, loadFreshChatRestoreIdReq, loadUserBettingBonuses,
    loadUserProfileReq,
    loadUserSettingsReq, loadUserStatsReq, loadUserSubscriptionsReq,
    putUserSubscriptionReq, restorePasswordRequestReq, restorePasswordRestoreReq, sendFreshChatRestoreIdReq,
    sendUserDataReq, updateAuthDetailsProvidersReq,
} from "@api";
import type { IPlayerStats, ISubscriptions, IUserSettings } from "@api";
import { EventBus as bus } from "@bus";
import { cioIdentifyUser } from "@controllers/CustomerIO";
import { useUserTermsAcceptingPopup } from "@controllers/userTermsAcceptingPopup";
import WS from "@controllers/WebsocketController";
import { FullStory } from "@front/core/controllers/MetriksController/FullStory";
import { userHasEmptyField } from "@helpers/helperPreCashboxPopup";
import vipStatusHelper from "@helpers/vipStatusHelper";
import { promiseAll } from "@mixins/promiseMixin";
import { usePopupNewProvider } from "@modules/Popups/PopupProviderNew/usePopupNewProviderController";
import * as Sentry from "@sentry/vue";
import { EnumContextFields, EnumFormFields } from "@src/types/common";
import type { IUserData, IUserInfo } from "@src/types/user";
import { useCommon } from "@store/common";
import { useStatusCompPointsStore } from "@store/compPoints/statusCompPointsStore";
import { useGamesFavorite } from "@store/games/gamesFavorite";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGiftsStore } from "@store/gifts";
import { useLootboxesStore } from "@store/lootboxes";
import { useLotteriesStore } from "@store/lotteries";
import { useMultilang } from "@store/multilang";
import { useTournamentsStore } from "@store/tournaments/tournamentsStore";
import { useUserBalance } from "@store/user/userBalance";
import { useUserLimits } from "@store/user/userLimits";
import { useUserStatuses } from "@store/user/userStatuses";
import config from "@theme/configs/config";
import { getStateByCounty } from "@theme/configs/stateFieldConfig";
import { defineStore, storeToRefs } from "pinia";
import type { Pinia } from "pinia";

const defaultUser: IUserData = {
    user_id: "",
    level: 1,
    hash: "",
    userDataIsSet: false,
    dataIsLoaded: false,
    dataUserLoadedOneTime: false,

    // data from SS
    id: "",
    email: "",
    auth_fields_missed: [],
    statuses: [],
    created_at: "",
    current_sign_in_at: "",
    confirmed_at: "",
    deposit_bonus_code: null,
    can_issue_bonuses: null,
    profession: null,
    autoregistered: false,
    autologin_link: null,
    verified: false,
    license_name: "",
    sow_questionnaire_expires_at: null,
    two_factor_enabled: false,
    address: null,
    nickname: null,
    gender: null,
    receive_promos: false,
    city: null,
    first_name: null,
    receive_sms_promos: false,
    postal_code: null,
    country: null,
    date_of_birth: null,
    last_name: null,
    currency: "",
    language: "",
    sumsub_verified: null,
};
export const useUserInfo = defineStore("userInfo", () => {
    const useCommonStore = useCommon();
    const { getDefaultCurrency, getCurrencyCrypto } = storeToRefs(useCommonStore);

    const info = ref<IUserData>(defaultUser);
    const settings = ref<IUserSettings>();
    const isLoadedUsedData = ref<boolean>(false);
    const freshchatRestoreIdLoaded = ref<boolean>(false);
    const freshchatRestoreId = ref<string>("");
    const isLogged = ref<boolean>(false);
    const subscriptions = ref<ISubscriptions>();
    const notice = ref<any[]>([]);
    const stats = ref<IPlayerStats>();
    const bettingBonuses = ref([]);

    const getUserInfo = computed<IUserInfo>(() => {
        return info.value;
    });

    const getUserBettingBonuses = computed<IUserInfo>(() => {
        return bettingBonuses.value;
    });

    const getUserSumsubVerified = computed(() => {
        return getUserInfo.value.sumsub_verified;
    });
    const getUserSubscriptions = computed(() => {
        return subscriptions.value;
    });
    const getUserCurrency = computed(() => {
        return info.value.currency || getDefaultCurrency.value;
    });

    const getIsLogged = computed(() => {
        return isLogged.value;
    });
    const getNotice = computed(() => {
        return notice.value;
    });
    const getSettings = computed(() => {
        return settings.value;
    });
    const getFreshChatRestoreIdLoaded = computed(() => {
        return freshchatRestoreIdLoaded.value;
    });
    const getFreshChatRestoreId = computed(() => {
        return freshchatRestoreId.value;
    });

    const isCryptoUserCurrency = computed(() => {
        return getCurrencyCrypto.value.includes(getUserCurrency.value);
    });

    const getHaveUserEmptyRequiredData = computed(() => {
        return userHasEmptyField({
            mobile_phone: info.value.mobile_phone,
            first_name: info.value.first_name,
            last_name: info.value.last_name,
            date_of_birth: info.value.date_of_birth,
            country: info.value.country,
            city: info.value.city,
            address: info.value.address,
            postal_code: info.value.postal_code,
        });
    });

    const getIsUserLoadedOneTime = computed(() => {
        return info.value.dataUserLoadedOneTime;
    });
    const getDataIsLoaded = computed(() => {
        return info.value.dataIsLoaded;
    });
    const getUserNickName = computed(() => {
        return info.value.nickname ? info.value.nickname : info.value.email;
    });
    const getIsLoadedUsedData = computed(() => {
        return isLoadedUsedData.value;
    });

    function setUserData(data) {
        const payload = {
            ...data,
            nick_name: data.nickname || data.email,
            locale: data.language,
            user_id: data.id,
            userDataIsSet: Boolean(data.first_name),
            dataIsLoaded: true,
        };
        info.value = Object.assign({}, info.value, payload);
        isLoadedUsedData.value = true;
    }

    function addUserGroup(userGroup) {
        info.value.statuses.push(userGroup);
    }

    function clearUserData() {
        const storeLimits = useUserLimits();
        const userBalanceStore = useUserBalance();
        const statusCompPointsStore = useStatusCompPointsStore();

        info.value = defaultUser;
        isLogged.value = false;
        notice.value = [];

        storeLimits.clearState();
        userBalanceStore.clearState();
        statusCompPointsStore.clearState();
    }

    function toggleUserIsLogged(status: boolean) {
        isLogged.value = status;
    }

    function setFreshChatRestoreIdLoaded(status: boolean = true) {
        freshchatRestoreIdLoaded.value = status;
    }

    function addUserStatuses(newStatus) {
        info.value.statuses = [
            info.value.statuses.filter(({ id }) => {
                return Number(id) !== newStatus.id;
            }),
            newStatus,
        ];
    }

    async function checkUserState() {
        const stateFieldConfig = useCommonStore.getFieldsType(EnumContextFields.edition, EnumFormFields.state);
        const userState = getUserInfo.value.state;
        const userPostCode = getUserInfo.value.postal_code;

        if (stateFieldConfig && !userState && userPostCode) {
            const stateForEdit = getStateByCounty(info.value.country, userPostCode);
            if (stateForEdit) {
                await sendUserData({
                    context: "edition",
                    player: { state: stateForEdit },
                });
            }
        }
    }

    async function putUserSubscription(data) {
        await putUserSubscriptionReq(data);
        return loadUserSubscriptions({ reload: false });
    }

    async function initLoadUserData() {
        return loadFullUserData();
    }

    async function loadFullUserData() {
        return promiseAll([
            loadBaseUserData(),
        ]);
    }

    async function loadBaseUserData({ route } = {}) {
        const { loadUserLimits } = useUserLimits();
        const {
            loadGiftsData,
            loadAdditionalDepositGifts,
            loadDepositGiftsData,
            loadRegistrationGiftsData,
            loadFSGiftsData,
        } = useGiftsStore();
        const { loadUserBalance } = useUserBalance();
        const { loadFavoriteGames } = useGamesFavorite();
        const { loadLootboxesList } = useLootboxesStore();
        const { loadLotteryStatuses } = useLotteriesStore();
        const { loadUserTournaments } = useTournamentsStore();
        const { loadRatesMoney, loadUserCompPoints } = useStatusCompPointsStore();
        const { loadLastGames } = useGamesCommon();

        try {
            const { data } = await loadUserProfile({ route });
            if (data?.id) {
                if ((!DEV || FORCE_RUN_ANALYTICS) && config.fullStoryId) {
                    FullStory.identify(info.value, config.fullStoryId);

                    const { status } = vipStatusHelper(data);
                    Sentry.setUser({ id: data.id, vip: Boolean(status) });
                }
                return await promiseAll([
                    loadUserBalance(),
                    loadUserSubscriptions({ reload: false }),
                    loadUserCompPoints(),
                    loadUserSettings(),
                    loadGiftsData(),
                    loadDepositGiftsData(),
                    loadAdditionalDepositGifts(),
                    loadRegistrationGiftsData(),
                    loadFSGiftsData(),
                    loadUserLimits(),
                    loadUserTournaments(),
                    loadRatesMoney(),
                    loadLotteryStatuses(),
                    loadFavoriteGames(),
                    loadUserStats(),
                    loadLootboxesList(),
                    loadLastGames(),
                    userSetToGroupForAbTest(),
                ]);
            }
            return data;
        } finally {
            WS.start();
        }
    }

    async function loadUserProfile({ reload = false, route } = {}) {
        const { checkToShowPopup } = usePopupNewProvider();
        const { runShowingTermsPopup } = useUserTermsAcceptingPopup();
        const multilang = useMultilang();

        const profile = info.value;
        if (profile.id && !reload) {
            cioIdentifyUser(profile);
            return { data: profile };
        }

        info.value.dataIsLoaded = false;
        try {
            const response = await loadUserProfileReq();
            if (response) {
                setUserData(response.data);

                runShowingTermsPopup();

                const responseLang = response.data.language;

                if (responseLang !== multilang.getUserLocale && response.data.id) {
                    multilang.updateLocale({ lang: responseLang, route });
                }

                if (!response.data.id) {
                    setFreshChatRestoreIdLoaded();
                    toggleUserIsLogged(false);
                    return;
                }
                toggleUserIsLogged(true);

                bus.$emit("user.data.received");
                bus.$emit("user.login", response.data);
                cioIdentifyUser(response.data);
                checkToShowPopup();

                loadFreshChatRestoreId();

                useCommon().loadPlayerFieldsInfo({ reload: true })
                    .then(checkUserState);
            }

            return response;
        } finally {
            info.value.dataIsLoaded = true;
            info.value.dataUserLoadedOneTime = true;
        }
    }

    async function loadUserSettings() {
        const data = await loadUserSettingsReq();
        if (data) {
            settings.value = data;
        }

        return data;
    }

    async function userSetToGroupForAbTest() {
        const ID_GROUP_FOR_PAIRED_ID = 543;
        const ID_GROUP_FOR_UNPAIRED_ID = 544;
        const userStatuses = useUserStatuses();
        const isUserIncludingInAB = userStatuses.getUserGroups.some((id) => {
            return id === ID_GROUP_FOR_PAIRED_ID || id === ID_GROUP_FOR_UNPAIRED_ID;
        });
        if (isUserIncludingInAB) {
            return;
        }
        const groupForAdding = info.value.id % 2 ? ID_GROUP_FOR_UNPAIRED_ID : ID_GROUP_FOR_PAIRED_ID;

        await addPlayerToGroup(groupForAdding);
    }

    async function sendUserData(data) {
        try {
            const resp = await sendUserDataReq(data);
            info.value.saved = true;
            await loadUserProfile({ reload: true });
            return resp;
        } catch (err) {
            throw err;
        }
    }

    async function restorePasswordRequest(payload) {
        try {
            return await restorePasswordRequestReq(payload);
        } catch (err) {
            throw err.response;
        }
    }

    async function restorePasswordRestore(payload) {
        try {
            return await restorePasswordRestoreReq(payload);
        } catch (err) {
            throw err;
        }
    }

    async function confirmPlayer(token: string) {
        try {
            return await confirmPlayerReq(token);
        } catch (err) {
            throw err;
        }
    }

    async function confirmEmailResend(captcha: string) {
        const dataForConfirm = {
            user: { email: info.value.email },
        };
        if (captcha) {
            dataForConfirm.user.captcha = captcha;
        }
        try {
            return await confirmEmailResendReg(dataForConfirm);
        } catch (err) {
            throw err;
        }
    }

    async function updateAuthDetailsProviders(data) {
        try {
            return await updateAuthDetailsProvidersReq(data);
        } catch (err) {
            throw err;
        }
    }

    async function loadFreshChatRestoreId() {
        setFreshChatRestoreIdLoaded(false);
        const data = await loadFreshChatRestoreIdReq(info.value.user_id);

        const restoreId = data?.restoreId;

        if (restoreId) {
            freshchatRestoreId.value = restoreId.trim();
        }
        setFreshChatRestoreIdLoaded();
    }

    async function sendFreshChatRestoreId(restoreId: string) {
        // to prevent override restore id when it is not initialized yet
        if (freshchatRestoreIdLoaded.value || restoreId === freshchatRestoreId.value) {
            return;
        }

        freshchatRestoreId.value = restoreId;
        setFreshChatRestoreIdLoaded();
        await sendFreshChatRestoreIdReq(info.value.user_id, restoreId);
    }

    async function loadUserSubscriptions({ reload = false }) {
        if (reload && subscriptions.value) {
            return Promise.resolve(subscriptions.value);
        }

        const data = await loadUserSubscriptionsReq();

        if (data) {
            subscriptions.value = data;
            info.value = { ...info.value, ...subscriptions };
        }

        return data;
    }

    async function loadUserStats() {
        const data = await loadUserStatsReq();

        if (data) {
            stats.value = data;
        }
        return data;
    }

    async function loadUserBonuses() {
        const data = await loadUserBettingBonuses();

        if (data) {
            bettingBonuses.value = data;
        }
        return data;
    }

    return {
        getUserInfo,
        getUserSumsubVerified,
        checkUserState,
        info,
        isLogged,
        getUserCurrency,
        isCryptoUserCurrency,
        getIsLogged,
        getNotice,
        getSettings,
        getHaveUserEmptyRequiredData,
        getFreshChatRestoreIdLoaded,
        getFreshChatRestoreId,
        getUserSubscriptions,
        getIsUserLoadedOneTime,
        getDataIsLoaded,
        getUserNickName,
        getIsLoadedUsedData,
        getUserBettingBonuses,

        toggleUserIsLogged,
        setUserData,
        addUserGroup,
        clearUserData,
        addUserStatuses,

        putUserSubscription,
        loadUserSettings,
        initLoadUserData,
        loadUserProfile,
        loadBaseUserData,
        sendUserData,
        restorePasswordRequest,
        restorePasswordRestore,
        confirmPlayer,
        confirmEmailResend,
        updateAuthDetailsProviders,
        sendFreshChatRestoreId,
        loadUserBonuses
    };
});

export function useUserInfoFetchService(pinia?: Pinia) {
    useUserInfo(pinia);

    function loadUserInfo() {
        return Promise.resolve();
    }

    return {
        loadUserInfo,
    };
}
