import featureFlags from "@theme/configs/featureFlags";

import askgamblers from "@modules/Footer/components/TrustBlock/img/askgamblers.svg";
import gamblingtherapy from "@modules/Footer/components/TrustBlock/img/gamblingtherapy.svg";
import nonStopBonus from "@modules/Footer/components/TrustBlock/img/nonStopBonus.svg";
import bestCasinoOperator from "@modules/Footer/components/TrustBlock/img/best-casino-operator.svg";
import bestMarketingCampaign from "@modules/Footer/components/TrustBlock/img/best-marketing-campaign.svg";
import CGuruBonus from "@modules/Footer/components/TrustBlock/img/CGuruBonus.svg";
import CGuruVoice from "@modules/Footer/components/TrustBlock/img/CGuruVoice.svg";
import casinoGuruFair from "@modules/Footer/components/TrustBlock/img/casino_guru_fair.svg";
import chipyToken from "@modules/Footer/components/TrustBlock/img/chipy-token.png";
import chipyTokenX2 from "@modules/Footer/components/TrustBlock/img/chipy-token@2x.png";
import slotCatalog from "@modules/Footer/components/TrustBlock/img/slotCatalog.png";
import slotCatalogX2 from "@modules/Footer/components/TrustBlock/img/slotCatalog@2x.png";
import askGamblersBestNewCasino22 from "@modules/Footer/components/TrustBlock/img/Ask-Gamblers-Best-New-Casino-22.svg";
import LCB2022 from "@modules/Footer/components/TrustBlock/img/LCB_2022.svg";
import sbc2022 from "@modules/Footer/components/TrustBlock/img/sbc-2022.svg";
import sbc2021 from "@modules/Footer/components/TrustBlock/img/sbc-2021.svg";
import gamblizard from "@modules/Footer/components/TrustBlock/img/gamblizard.svg";
import mrGamble from "@modules/Footer/components/TrustBlock/img/mr-gamble.svg";
import grenzenlosCasinoYnew from "@modules/Footer/components/TrustBlock/img/grenzenlos-casino.svg";
import casinosanalyzer from "@modules/Footer/components/TrustBlock/img/casinosanalyzer.svg";
import zamsino from "@modules/Footer/components/TrustBlock/img/zamsino.svg";
import casinolandia from "@modules/Footer/components/TrustBlock/img/casinolandia.svg";
import AskGamblersBestCasino23 from "@modules/Footer/components/TrustBlock/img/Ask-Gamblers-Best-Casino-23.svg";
import eCOGRA from "@modules/Footer/components/TrustBlock/img/eCOGRA.svg";
import mallOfCasino from "@modules/Footer/components/TrustBlock/img/mall-of-casino.svg";
import trueBlue from "@modules/Footer/components/TrustBlock/img/true-blue.svg";
import noDeposit from "@modules/Footer/components/TrustBlock/img/no-deposit.svg";
import casinosTest from "@modules/Footer/components/TrustBlock/img/casinos-test.svg";
import casinoBonusClub from "@modules/Footer/components/TrustBlock/img/casino-bonus-club.svg";
import deutchlandCasinos from "@modules/Footer/components/TrustBlock/img/deutchland-casinos.svg";
import casinosHub from "@modules/Footer/components/TrustBlock/img/casinos-hub.svg";
import casinoBonusesNow from "@modules/Footer/components/TrustBlock/img/casino-bonuses-now.svg";
import gsbSeal from "@modules/Footer/components/TrustBlock/img/GCB_Seal.svg";

import * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";

export default {
    featureFlags,
    currencyDefault: "EUR",
    tagManagerId: "GTM-KSRJF8L",
    fullStoryId: "FWWXX",
    hotjar_id: "", // "2028617",
    freshChat: {
        token: "c5a3428f-39f5-40ea-95bb-bb13c892c87a",
        widgetUuid: "527c097a-d608-401d-97b5-0bf8fa952fe5",
    },
    mainDomaine: "rocketplay.com",
    payments: [
        "visa_mastercard", "coins_paid",
        "bitcoin_cash", "bitcoin", "dogecoin",
        "pay_by_voucher", "litecoin", "tether",
        "etherum", "kluwp", "inpay", "bank_transfer",
        "zimpler", "neosurf", "mifinity", "skrill",
        "neteller", "venuspoint", "paysafecard",
        "idebit", "instadebit", "online_bank_transfer",
        "cardpay", "cashtocode", "eco_payz", "muchbetter",
        "combined", "flexepin", "volt", "directbankingeu",
        "rapidtransfer", "bankintl", "noda",
    ],
    paymentsEE: [
        "visa_mastercard", "skrill", "neteller",
        "interac_finteqhub", "mifinity", "muchbetter",
        "paysafecard", "inpay",
    ],
    PROMO_TOOLTIP_TIMER_MINUTES: 10,
    socials: [
        {
            name: "instagram",
            url: "https://www.instagram.com/rocketplaycasino_/",
            icon: FeIconConfig.icons.social.socialInstagram,
        },
        {
            name: "facebook",
            url: "https://www.facebook.com/rocketplaycasino",
            icon: FeIconConfig.icons.social.socialFacebook,
        },
        {
            name: "twitter",
            url: "https://twitter.com/RocketPlay5",
            icon: FeIconConfig.icons.social.socialTwitter,
        },
        {
            name: "whatsapp",
            url: "https://whatsapp.com/channel/0029Va6O4v311ulJb5xnqB3Q",
            icon: FeIconConfig.icons.social.socialWhatsapp,
        },
    ],
    awardsNominations: [
        {
            icon: bestMarketingCampaign,
            blank: false,
            index: 1,
            alt: "Best marketing campaign SBC AWARDS 2023",
        },
        {
            icon: bestCasinoOperator,
            blank: false,
            index: 2,
            alt: "Best casino operator SBC AWARDS 2023",
        },
        {
            icon: AskGamblersBestCasino23,
            blank: true,
            index: 3,
            alt: "Casino Guru Awards Bonus 2023",
        },
        {
            icon: CGuruBonus,
            blank: true,
            to: "https://casino.guru/awards",
            index: 4,
            alt: "Casino Guru Awards Bonus 2023",
        },
        {
            icon: CGuruVoice,
            blank: true,
            to: "https://casino.guru/awards",
            index: 5,
            alt: "Casino Guru Awards Voice 2023",
        },
        {
            icon: LCB2022,
            blank: false,
            index: 6,
            alt: "LCB2022",
        },
        {
            icon: askGamblersBestNewCasino22,
            blank: false,
            index: 7,
            alt: "World's Most Prestigious Casino Awards | AskGamblers Awards",
        },
        {
            icon: sbc2021,
            blank: false,
            index: 8,
            alt: "SBC AWARDS 2021",
        },
        {
            icon: sbc2022,
            blank: true,
            to: "https://sbcevents.com/sbc-awards/#shortlists",
            index: 9,
            alt: "SBC AWARDS 2022",
        },
        {
            icon: nonStopBonus,
            blank: true,
            to: "https://www.nonstopbonus.com/r/rocketplay-casino-review",
            index: 10,
            alt: "casino of the month",
        },
    ],
    trust: [
        {
            icon: askgamblers,
            blank: true,
            to: "https://www.askgamblers.com/online-casinos/reviews/rocketplay-casino",
            index: 1,
            alt: "askgamblers",

        },
        {
            icon: casinoGuruFair,
            blank: true,
            to: "https://casino.guru/rocketplay-casino-review",
            index: 2,
            alt: "Casino Guru Fair Casino",
        },
        {
            image: {
                main: chipyToken,
                retina: chipyTokenX2,
            },
            blank: true,
            to: "https://chipy.com/reviews/rocketplay-casino-review",
            index: 3,
            alt: "Сhippy Token of Trust",
        },
        {
            icon: casinosanalyzer,
            blank: true,
            to: "https://casinosanalyzer.com/online-casinos",
            index: 4,
            alt: "Top Rated Online Casinos - #1 Best Online Casinos",
        },
        {
            image: {
                main: slotCatalog,
                retina: slotCatalogX2,
            },
            blank: true,
            to: "https://slotcatalog.com/en/casino/RocketPlay-Casino",
            index: 5,
            alt: "Slot Catalog",
        },
        {
            icon: mrGamble,
            blank: true,
            to: "https://mr-gamble.com/ca/online-casino/rocketplay-casino/",
            index: 6,
            alt: "Mr Gamble - Rocketplay Casino review, bonus, free spins, and real player reviews",
        },
        {
            icon: zamsino,
            blank: true,
            to: "https://zamsino.com/ie/new-casinos/",
            index: 7,
            alt: "New Online Casinos in Ireland 2022 🎖️",
        },
        {
            icon: casinolandia,
            blank: true,
            to: "https://casinolandia.com/casinos/rocketplay-casino/",
            index: 8,
            alt: "CasinoLandia | An Exciting Journey in the Land of Online Casinos",
        },
        {
            icon: gamblizard,
            blank: true,
            to: "https://gamblizard.de/casinos/rocket-play-boni-und-freispiele/",
            index: 9,
            alt: "Gamblizard",
        },
        {
            icon: grenzenlosCasinoYnew,
            blank: true,
            to: "https://www.grenzenloscasino.com/",
            index: 10,
            alt: "Casinos für Deutsche Spieler - Grenzenlos Casino Spielen",
        },
        {
            icon: mallOfCasino,
            blank: true,
            to: "https://mallofcasino.ca/",
            index: 11,
            alt: "Mall Of Casino",
        },
        {
            icon: trueBlue,
            blank: true,
            to: "https://www.truebluecasinos.org/",
            index: 12,
            alt: "True Blue Casinos",
        },
        {
            icon: noDeposit,
            blank: true,
            to: "https://www.nodeposit.org/",
            index: 13,
            alt: "No Deposit",
        },
        {
            icon: casinosTest,
            blank: true,
            to: "https://www.casinostest.org/",
            index: 14,
            alt: "Casino Test",
        },
        {
            icon: casinoBonusClub,
            blank: true,
            to: "https://casino-bonus.club/",
            index: 15,
            alt: "Casino Bonus",
        },
        {
            icon: deutchlandCasinos,
            blank: true,
            to: "https://www.deutschlandcasinos.org/",
            index: 16,
            alt: "Deutschland Casinos",
        },
        {
            icon: casinosHub,
            blank: true,
            to: "https://www.casinoshub.com/",
            index: 17,
            alt: "Casinos Hub",
        },
        {
            icon: casinoBonusesNow,
            blank: true,
            to: "https://www.casinobonusesnow.com/",
            index: 18,
            alt: "Casino Bonuses Now",
        },
    ],
    gcbValidator: {
        icon: gsbSeal,
        blank: true,
        // eslint-disable-next-line max-len
        to: "https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbVJNWW5ObFZtbFNkRzFLY2s0NUsyRjFhR1ZTTjNjOVBTSXNJblpoYkhWbElqb2lPRXBoZW5Sd1Nsa3hSRk15VEhaa1MxcHZZVE5DZHowOUlpd2liV0ZqSWpvaVlqWTJNbU15TXpobU1XUm1OREE0WVRrMk5qRXlaRFEyTWpneE9EVTVPR1U0TmpWbU9XSm1NalZtWWpreFl6Z3lOVGxrWVRaa01tRmxNbVJoTkdJek5TSXNJblJoWnlJNklpSjk=",
        alt: "Curaçao Gaming Control Board",
    },
    licenses: [
        {
            icon: gamblingtherapy,
            blank: true,
            to: "https://www.gamblingtherapy.org/",
            alt: "Gambling Therapy",
        },
        {
            icon: eCOGRA,
            blank: true,
            to: "https://secure.ecogra.org/certification/7fb091c2-035d-4eb8-a7e5-96875087e61b",
            alt: "eCOGRA",
        },

    ],
    contacts: {
        phone: "02 6190 7929",
        email: "support@rocketplay.com",
    },
    defaultAcceptTerms: false,
    idlePageTitle: {
        enabled: true,
        texts: "idlePageTitle",
        idleStartDelay: 0,
        changeDelay: 2000,
    },
    depositFistAmount: 50,
    theme: "theme-light",
    showEmpCard: false,
};
