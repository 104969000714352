<template>
    <BookmarksItem
        v-if="showLootboxBookmark"
        :image="spinWheel"
        @bookmark-click="handleClickBookmark"
    >
        <div
            v-if="countActiveLootbox"
            class="lootbox-bookmark__counter"
        >
            {{ countActiveLootbox }}
        </div>
    </BookmarksItem>
</template>

<script>
import { routeNames } from "@router/routeNames";

import BookmarksItem from "@modules/BookmarksList/item.vue";
import spinWheel from "@views/LootBoxPage/img/spin-wheel-widget.svg";
import spinWheelMock from "@views/LootBoxPage/img/spin-wheel-widget-mock.svg";
import { mapState } from "pinia";
import { useLootboxesStore } from "@store/lootboxes";
import { useEnvironments } from "@store/environments";

export default {
    name: "LootboxBookmark",

    components: {
        BookmarksItem,
    },

    computed: {
        ...mapState(useLootboxesStore, {
            countActiveLootbox: "countActiveLootbox",
        }),

        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),

        spinWheel() {
            return this.environment.useMocker ? spinWheelMock : spinWheel;
        },

        showLootboxBookmark() {
            return this.isLogged &&
                this.countActiveLootbox > 0 &&
                this.$route.name !== routeNames.spinWheelPage;
        },
    },

    methods: {
        handleClickBookmark() {
            this.$router.push({ name: routeNames.spinWheelPage });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.lootbox-bookmark {
    &__counter {
        @include font-size--Caption2;
        background: var(--color-secondary-2);
        border-radius: 1rem;
        width: 1rem;
        height: 1rem;
        text-align: center;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
}
</style>
