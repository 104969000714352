import log from "@controllers/Logger";
import { useLootboxesStore } from "@store/lootboxes";
import { defineStore } from "pinia";

import { http } from "@api/http";
import { addPlayerToGroup } from "@api/requests";
import { EventBus as bus } from "@bus";
import ABTestController from "@controllers/ABTest/ABTestController";
import authController from "@controllers/authController";
import CoveryController from "@front/core/controllers/CoveryController";
import { useGamesFavorite } from "@store/games/gamesFavorite";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGiftsStore } from "@store/gifts";
import { useLotteriesStore } from "@store/lotteries";
import { useNoticesStore } from "@store/notices";
import { useQuestStore } from "@store/quest/questStore";
import { useTournamentsStore } from "@store/tournaments/tournamentsStore";
import { useUserInfo } from "@store/user/userInfo";
import featureFlags from "@theme/configs/featureFlags";

export enum IbizaKeysValidation {
    BLACKLISTED = "IBIZA::INVALID::BLACKLISTED",
    INVALID_DOMAIN = "IBIZA::INVALID::INVALID_DOMAIN",
    REJECTED_EMAIL = "IBIZA::INVALID::REJECTED_EMAIL",
    RISKY = "IBIZA::RISKY::RISKY",
    NON_PERSONAL = "IBIZA::RISKY::NON_PERSONAL",
    INBOX_FULL = "IBIZA::RISKY::INBOX_FULL",
    TEMPORARY = "IBIZA::RISKY::TEMPORARY",
    TIMEOUT = "IBIZA::RISKY::TIMEOUT",
    ACCEPTED_EMAIL = "IBIZA::VALID::ACCEPTED_EMAIL",
    WHITELISTED = "IBIZA::VALID::WHITELISTED",
}

interface IRespIbizaService {
    errors: {
        message: IbizaKeysValidation;
    };
}

export const useAuth = defineStore("auth", () => {
    const {
        toggleUserIsLogged,
        loadUserProfile,
        setUserData,
        addUserGroup,
        loadBaseUserData,
        clearUserData
    } = useUserInfo();
    async function checkEmailVerify(email: string): Promise<IRespIbizaService> {
        try {
            const { data } = await http().post("/check-email", { email });
            return data;
        } catch (response) {
            log.error("CHECK_EMAIL_VERIFY_ERROR", response);
            throw response;
        }
    }

    async function loginTwoFactor(otp: string) {
        try {
            const { data } = await http().post("/api/users/sign_in", {
                user: {
                    otp_attempt: otp,
                }
            });
            toggleUserIsLogged(true);
            await loadUserProfile({});
            return data;
        } catch ({response}) {
            log.error("LOGIN_TWO_FACTORS_ERROR", response);
            throw response.data;
        }
    }

    async function login({ email, password, captcha, route }) {
        try {
            const {data} = await http().post("/api/users/sign_in", {
                user: {
                    email,
                    password,
                    dfpc: CoveryController.deviceFingerprint(),
                    captcha,
                },
            });

            toggleUserIsLogged(true);
            await loadBaseUserData({route});
            return data;
        } catch ({response}) {
            log.error("LOGIN_ERROR", response);
            throw response;
        }
    }

    async function logout(redirect: string) {
        try {
            await http().delete("/api/users/sign_out");
            authController.resetAuthData(redirect);
        } catch ({response}) {
            log.error("LOGOUT_ERROR", response);
        }
    }
    async function registration(registrationData) {
        if (registrationData.user) {
            registrationData.user.dfpc = CoveryController.deviceFingerprint();
        }
        try {
            const {data} = await http().post("/api/users", registrationData);
            setUserData(data);
            if (featureFlags.enableABReg) {
                addUserGroup({ id: ABTestController.groupById });
                await addPlayerToGroup(ABTestController.groupById);
            }

            toggleUserIsLogged(true);
            await loadBaseUserData({});
            bus.$emit("user.registration");

            return data;
        } catch ({response}) {
            log.error("REGISTRATION_ERROR", response);
            throw response;
        }
    }

    async function resetAuthData() {
        if (window?.PaymentsAPI) {
            await window.PaymentsAPI.resetCache();
        }
        const giftStore = useGiftsStore();
        const questStore = useQuestStore();
        const lotteriesStore = useLotteriesStore();
        const gamesFavoriteStore = useGamesFavorite();
        const noticesStore = useNoticesStore();
        const tournamentsStore = useTournamentsStore();
        const gamesStore = useGamesCommon();
        const lootboxesStore = useLootboxesStore();

        toggleUserIsLogged(false);
        clearUserData();

        tournamentsStore.clearTournamentUserData();
        gamesFavoriteStore.clearUserData();
        giftStore.giftsStoreClear();
        questStore.clearQuestUserData();
        lotteriesStore.clearLotteriesUserData();
        noticesStore.clearUserNotification();
        gamesStore.clearRecentGames();
        lootboxesStore.clearLootboxesUserData();
    }

    return {
        checkEmailVerify,
        loginTwoFactor,
        login,
        logout,
        registration,
        resetAuthData
    };
});
