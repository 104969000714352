import type { IPageItemCMS } from "@api/DTO/CMS";
import type { ILootboxesFileConfig } from "@api/DTO/lootboxes";
import { http } from "@api/http";
import log from "@controllers/Logger";
import type { UserGroup } from "@src/types/user";

export async function loadMockLootboxWheelConfigs():
    Promise<ILootboxesFileConfig | Error | undefined> {
    try {
        const { data } = await http().get<ILootboxesFileConfig>("/api/fe/config/rocket-wheel-config-segments");
        return data;

    } catch (err) {
        log.error("LOAD_MOCK_SECTIONS_ROCKET_WHEEL_ERROR", err);
        throw err;
    }
}

export async function loadPageContentFromCmsReq(slugPage: UserGroup): Promise<IPageItemCMS | void> {
    try {
        const { data } = await http().get<IPageItemCMS>(`/api/cms/pages/${ slugPage }`);
        return data;
    } catch (err) {
        log.error("LOAD_PAGE_CONTENT_FORM_CMS_REQ_ERROR", err);
    }
}

export async function loadPageContentFromWheelCmsReq(userId: UserGroup): Promise<IPageItemCMS | void> {
    try {
        const { data } = await http().get<IPageItemCMS>(`/api/cms/pages/rocket-wheel/${ userId }`);
        return data;
    } catch (err) {
        log.error("LOAD_PAGE_CONTENT_FORM_CMS_REQ_ERROR", err);
    }
}
