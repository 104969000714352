<template>
    <BookmarksItem
        v-if="showBookmark"
        :image="widgetImage"
        @bookmark-click="handleBookmarkClick"
    />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import BookmarksItem from "@modules/BookmarksList/item.vue";
import widgetImage from "./img/sv_widget.svg";

const SANTA_VEGAS_PATH = "/santa-vegas";

const $route = useRoute();
const $router = useRouter();

const showBookmark = computed<boolean>(() => {
    return $route.path !== SANTA_VEGAS_PATH;
});

function handleBookmarkClick() {
    $router.push(SANTA_VEGAS_PATH);
}
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.bookmark__item {
    padding: 0;

    :deep(.bookmark-item__logo) {
        width: 4rem;
        height: 4rem;

        @include media(XM) {
            width: 6rem;
            height: 6rem;
        }
    }
}
</style>
