<template>
    <div @click="openGift">
        <component
            :is="giftComponents[gift.type]"
            :gift="gift"
            :type-gift="typeGift"
        />
    </div>
</template>

<script>
import { TYPE_GIFT_BONUS, TYPE_GIFT_DEPOSIT, TYPE_GIFT_FS } from "@config/gift.js";
import { defineAsyncComponent } from "vue";
import { useUIStore } from "@store/ui";

const DepositGift = defineAsyncComponent(() => {
    return import("@modules/Gifts/components/DepositGift/DepositGift.vue");
});

const BonusGift = defineAsyncComponent(() => {
    return import("@modules/Gifts/components/BonusGift/BonusGift.vue");
});

const FSGift = defineAsyncComponent(() => {
    return import("@modules/Gifts/components/FSGift/FSGift.vue");
});

const giftComponents = {
    [TYPE_GIFT_DEPOSIT]: DepositGift,
    [TYPE_GIFT_BONUS]: BonusGift,
    [TYPE_GIFT_FS]: FSGift,
};

export default {
    name: "SuperGift",

    props: {
        gift: {
            type: Object,
            required: true,
        },
        typeGift: {
            type: String,
        },
    },
    setup() {
        const { setShowModal } = useUIStore();

        return {
            setShowModal,
        };
    },

    data() {
        return {
            giftComponents,
        };
    },

    methods: {
        async openGift() {
            this.setShowModal({
                name: `gift-${ this.gift.type }`,
                component: giftComponents[this.gift.type],
                showClose: true,
                props: {
                    size: "big",
                    gift: this.gift,
                    typeGift: this.typeGift,
                },
                mobileFriendly: true,
            });
        },
    },
};
</script>
