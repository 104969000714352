<template>
    <div
        v-if="lotteryListIsNotEmpty"
        class="ticket-balance__wrapper"
        @click="handleClickLotteryTickets"
    >
        <div>
            <div class="gap-column-xs align-items-center">
                <span class="ticket-balance__title">{{ $t("LOTTERIES.LOTTERY_TICKETS_BALANCE") }}</span>
                <Tooltip class="ticket-balance__tooltip">
                    <template #activator>
                        <div @click.stop>
                            <FeIcon :icon="FeIconConfig.icons.fill.info1" class="ticket-balance__icon-info"/>
                        </div>
                    </template>
                    <span>{{ $t("LOTTERIES.LOTTERY_TICKETS_BALANCE_INFORMER") }}</span>
                </Tooltip>
            </div>
        </div>
        <div class="gap-column-xs align-items-center">
            <div class="ticket-balance__count">
                {{ commonTicketsBalance }}
            </div>
            <FeImage
                :src="iconLotteryTicket"
                :src-retina="iconLotteryTicketRetina"
                class="ticket-balance__icon"
            />
        </div>
    </div>
</template>

<script>
import FeImage from "@ui/FeImage/FeImage.vue";
import { routeNames } from "@router/routeNames";

import iconLotteryTicket from "@modules/Lottery/LotteryPage/images/icon-lottery-ticket.png";
import iconLotteryTicketRetina from "@modules/Lottery/LotteryPage/images/icon-lottery-ticket@2.png";
import FeIcon, * as FeIconConfig from "@ui/FeIcon/FeIcon";
import Tooltip from "@components/Tooltip/Tooltip.vue";
import { mapState } from "pinia";
import { useLotteriesStore } from "@store/lotteries";

export default {
    name: "LotteryTicketsBalance",

    components: {
        Tooltip,
        FeIcon,
        FeImage,
    },

    data() {
        return {
            FeIconConfig,
            iconLotteryTicket,
            iconLotteryTicketRetina,
        };
    },

    computed: {
        ...mapState(useLotteriesStore, {
            commonTicketsBalance: "getCommonTicketsBalance",
            lotteriesList: "getLotteriesList",
        }),

        lotteryListIsNotEmpty() {
            return this.lotteriesList.length > 0;
        },
    },

    methods: {
        handleClickLotteryTickets() {
            this.$router.push({ name: routeNames.tournamentsLottery });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.ticket-balance {
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__icon-info {
        display: flex;
    }

    &__icon-info, &__icon {
        width: 1rem;
        height: 1rem;
    }

    &__title {
        @include font-size--Caption2($weight: $font-weight--normal);
        color: var(--color-text-body);
    }

    &__count {
        @include font-size--Caption1;
    }

    &__tooltip {
        position: relative;
    }
}
</style>
