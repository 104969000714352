<template>
    <!--  TODO: move theme-dark to store getter  -->
    <div
        id="app-layout"
        class="container theme-dark"
        :class="[ route.name ]"
    >
        <router-view v-slot="{ Component }">
            <SessionTime v-if="isMobile" class="session-time"/>

            <transition :mode="mode" :name="anim">
                <component :is="Component"/>
            </transition>
        </router-view>
        <ChatWidget/>
        <transition mode="out-in" name="fade">
            <ModalController/>
        </transition>
        <PreloaderController/>

        <toast group="basic"/>

        <AcceptedMessages :show-popup-e-m-p="showEMP"/>

        <MockCheck/>
    </div>
</template>


<script>
import config from "@theme/configs/config";
import manifest from "@theme/manifest";
import log from "@controllers/Logger";

import { mapActions, mapState } from "pinia";
import { useUserStatuses } from "@store/user/userStatuses";

import IdleTitle from "@controllers/IdleTitle";
import ModalController from "@modules/ModalController/ModalController.vue";
import PreloaderController from "@modules/PreloaderController/PreloaderController.vue";
import Toast from "@modules/Toast/Toast.vue";
import ChatWidget from "@modules/ChatWidget/ChatWidget.vue";
import toast from "@plugins/Toast";

import CoveryController from "@front/core/controllers/CoveryController.js";
import AcceptedMessages from "@modules/AcceptedMessages/AcceptedMessages.vue";
import MockCheck from "@components/MockCheck/MockCheck.vue";

import imageLogo from "@theme/images/BrandImages/logo.svg";
import fav16 from "@theme/images/BrandImages/favicon/favicon_16.png";
import fav32 from "@theme/images/BrandImages/favicon/favicon_32.png";

import { defineComponent } from "vue";
import WS from "@controllers/WebsocketController";
import { routeNames } from "@router/routeNames.js";
import { metaHrefLangsLink } from "@helpers/metaHrefLangsLink";
import { useUserLimits } from "@store/user/userLimits";
import { useGiftsStore } from "@store/gifts.ts";
import { useJackpots } from "@store/jackpots";
import { useUserBalance } from "@store/user/userBalance";
import { useEmailConfirmation } from "@helpers/EmailConfirmation.ts";
import { useContextStore } from "@store/context";
import { useEscapePopupStrategy } from "@src/strategies/ios-webview-escape";
import { usePrefetchData } from "@services/prefetch";
import { canonicalHelper } from "@helpers/canonicalHelper";

import Modal from "@plugins/Modal";
import { useCommon } from "@store/common";
import { useCashboxStore } from "@store/cashboxStore";
import { useLootboxesStore } from "@store/lootboxes";
import { useQuestStore } from "@store/quest/questStore";
import { defineAsyncComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useGlobalHandler } from "@controllers/handlerGlobalAttrs";
import { useTournamentsStore } from "@store/tournaments/tournamentsStore";
import { useFreshchatSurvey } from "@services/freshchat/survey";
import { loadStartData, promiseAll } from "@mixins/promiseMixin";
import { beforeRouteUpdate } from "@src/mixins/serverPrefetch.ts";
import { showCanceledDepToast } from "@helpers/cashboxHelpers";
import SessionTime from "@components/SessionTime/SessionTime.vue";
import { useLicense } from "@helpers/license";

import { jsonLD } from "@src/thems/alpa/configs/jsonld/index.js";
import { useEnvironments } from "@store/environments";
import { useMultilang } from "@store/multilang";
import { useGamesCommon } from "@store/games/gamesStore";
import { usePixelCkGRD } from "@controllers/MetriksController/pixelCkGRD";

import { isServer } from "@helpers/ssrHelpers";
import { useCMS } from "@store/CMS";
import FeButton from "@ui/New/FeButton.vue";
import { useUserInfo } from "@store/user/userInfo";
import { useStatusCompPointsStore } from "@store/compPoints/statusCompPointsStore";
import { useRootStore } from "@store/root";
import { getUtmMetricsLogAction, UtmMetricsActions } from "@api/requests/metrics";
import { useRoute } from "vue-router";

const jackpotWinPopup = defineAsyncComponent(() => {
    return import("@components/JackpotsCustom/JackpotWinPopup.vue");
});

export default defineComponent({
    name: "App",

    components: {
        FeButton,
        SessionTime,
        ModalController,
        PreloaderController,
        Toast,
        AcceptedMessages,
        ChatWidget,
        MockCheck,
    },

    beforeRouteUpdate,
    async serverPrefetch() {
        const promises = this.loadStartData(this.$store, this.$route);

        promises.push(
            this.loadMetaSEO(this.$route),
            this.loadUserSettings(),
            this.loadLocales(this.$route?.query),
            this.loadGamesJackpots(),
        );


        if (this.isLogged) {
            promises.push(
                this.initLoadUserData(),
            );
        } else {
            const resp = await this.updateUserProfile();
            if (resp?.data.id) {
                await this.initLoadUserData();
            }
            this.loadPlayerFieldsInfo();
        }
        this.loadCategoriesFileConfig();
        return this.promiseAll(promises).then(() => {
            this.loadQuestsData();

            if (!isServer) {
                if (!this.isLogged) {
                    WS.start();
                }
            }

            if (this.isLicenseEE) {
                this.loadCountries({ reload: true });
            }

            showCanceledDepToast();
            this.updateUrlPixelCkGRD();
            this.loadMockSectionsRocketWheel();
        });
    },

    setup() {
        const { isLicenseEE } = useLicense();
        const { updateUrlPixelCkGRD } = usePixelCkGRD();
        const { loadCountries } = useCommon();
        const { loadMockSectionsRocketWheel } = useLootboxesStore();
        const { loadMetaSEO, setNoIndex } = useCMS();

        const route = useRoute();

        return {
            isLicenseEE,
            updateUrlPixelCkGRD,
            loadCountries,
            loadMockSectionsRocketWheel,
            loadMetaSEO,
            setNoIndex,
            route,
        };
    },

    data() {
        return {
            anim: "layout",
            mode: "out-in",
            showChat: false,
            showEMP: config.showEmpCard,
            emailConfirmation: useEmailConfirmation(),
            isCaptchaInit: false,
        };
    },

    head() {
        let noIndex = () => {
            if (Boolean(this.seoMeta.isNoindexPath) || Boolean(this.noIndex)) {
                return {
                    name: "robots",
                    content: "noindex",
                };
            }
            return {};
        };

        let hostname = "";
        let pathname = "";
        if (!isServer) {
            hostname = window.location.hostname;
            pathname = window.location.pathname;
        }

        let metaTitle = this.seoMeta?.metaTitle || "";
        if (!isServer && !metaTitle) {
            metaTitle = document.title;
        }

        let metaDescription = this.seoMeta?.metaDescription || "";
        if (!isServer && !metaDescription) {
            metaDescription = document.querySelector("meta[name=\"description\"]")?.content;
        }

        if (!isServer) {
            const canonicalTag = document.querySelector("link[rel=\"canonical\"]");

            if (canonicalTag) {
                canonicalTag.href = canonicalHelper(hostname, pathname);
            } else {
                const newCanonicalTag = document.createElement("link");
                newCanonicalTag.rel = "canonical";
                newCanonicalTag.href = canonicalHelper(hostname, pathname);
                document.head.appendChild(newCanonicalTag);
            }
        }

        return {
            htmlAttrs: {
                lang: this.userLocale || "en",
            },
            title: metaTitle,
            titleTemplate: null,
            link: [
                { rel: "icon", type: "image/png", sizes: "16x16", href: fav16 },
                { rel: "icon", type: "image/png", sizes: "32x32", href: fav32 },

                { rel: "apple-touch-startup-image", href: "/static/apple-touch-icon.png?url" },
                { rel: "apple-touch-icon", href: "/static/apple-touch-icon.png?url" },
                { rel: "apple-touch-icon", sizes: "120x120", href: "/static/apple-touch-icon-120x120.png?url" },
                { rel: "apple-touch-icon", sizes: "152x152", href: "/static/apple-touch-icon-152x152.png?url" },
                { rel: "apple-touch-icon", sizes: "167x167", href: "/static/apple-touch-icon-167x167.png?url" },
                { rel: "apple-touch-icon", sizes: "180x180", href: "/static/apple-touch-icon.png?url" },
                {
                    rel: "apple-touch-icon-precomposed",
                    sizes: "120x120",
                    href: "/static/apple-touch-icon-120x120-precomposed.png?url",
                },
                {
                    rel: "apple-touch-icon-precomposed",
                    sizes: "152x152",
                    href: "/static/apple-touch-icon-152x152-precomposed.png?url",
                },
                {
                    rel: "apple-touch-icon-precomposed",
                    sizes: "167x167",
                    href: "/static/apple-touch-icon-167x167-precomposed.png?url",
                },
                {
                    rel: "apple-touch-icon-precomposed",
                    sizes: "180x180",
                    href: "/static/apple-touch-icon-precomposed.png?url",
                },

                ...metaHrefLangsLink(hostname, this.$route.path),
                { rel: "stylesheet", href: `/img/sprites/products-sprite.css?${this.environment.version}` },
            ],
            meta: [
                noIndex(),
                { name: "description", content: metaDescription },
                { name: "apple-mobile-web-app-status-bar-style", content: manifest.theme_color },
                { name: "theme-color", content: manifest.theme_color },
                { name: "viewport-fit", content: "cover" },
                { name: "apple-mobile-web-app-title", content: manifest.short_name },
                { name: "apple-mobile-web-app-capable", content: "yes" },
                { name: "google-site-verification", content: "77WlwzI_1CFRmHx5lYvGRkyctvmHmDcXRh8QYVB20IE" },

                { property: "og:title", content: metaTitle },
                { property: "og:description", content: metaDescription },
                { property: "og:locale", content: this.userLocale },
                { property: "og:type", content: "website" },
                { property: "og:url", content: `https://${hostname}${pathname}` },
                { property: "og:image", content: `https://${hostname}${imageLogo}` },
                { property: "og:site_name", content: "RocketPlay Casino" },
            ],
            script: [
                jsonLD({
                    hostname,
                    routname: this.$route.name,
                }),
            ],
        };
    },
    computed: {
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
            notice: "getNotice",
        }),
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),
        ...mapState(useEnvironments, {
            environment: "getEnvironments",
        }),
        ...mapState(useUserStatuses, {
            currentLevel: "getUserLevelInfo",
        }),
        ...mapState(useUserLimits, [
            "isOneLimitReached",
        ]),
        ...mapState(useContextStore, {
            isBot: "isBotUA",
        }),
        ...mapState(useMultilang, {
            userLocale: "getUserLocale",
        }),
        ...mapState(useCMS, {
            seoMetaData: "seoMeta",
            noIndex: "noIndex",
        }),

        seoMeta() {
            const keyMetaData = this.$route.name === routeNames.main ?
                "/home" :
                this.$route.fullPath;
            return this.seoMetaData[keyMetaData] || {};
        },
    },

    watch: {
        $route(to) {
            this.$bus.$emit("sidebar.close");
            this.loadMetaSEO(to);
        },
        setup() {
            return { ...useI18n() };
        },
        isOneLimitReached(val) {
            if (val) {
                toast.show({
                    text: this.$t("NOTIFICATIONS.ON_LIMIT_REACHED"),
                    image: "warning",
                    id: Date.now(),
                    url: null,
                    type: "warning",
                    time: 0,
                });
            }
        },
    },

    created() {
        getUtmMetricsLogAction(UtmMetricsActions.SET_UTM);

        this.setNoIndex(this.$route.path.split("/").length > 4);
        useGlobalHandler();
        useFreshchatSurvey();
    },

    beforeMount() {
        window.__forceSmoothScrollPolyfill__ = true;
    },

    mounted() {
        const prefetchData = usePrefetchData();
        prefetchData();

        if (!this.isBot) {
            CoveryController.addCoveryScript();
        }

        this.$bus.$on("websocket.bonuses_changes", this.updateGifts);
        this.$bus.$on("websocket.freespins_changes", this.loadFSGiftsData);
        this.$bus.$on("websocket.comps_award", this.updateCompsAward);
        this.$bus.$on("websocket.payments_changes", this.userBalanceCommit);
        this.$bus.$on("websocket.tournaments_statuses", this.updateTournamentStatuses);
        this.$bus.$on("websocket.lootboxes_changes", this.updateLootboxList);
        this.$bus.$on("websocket.public:jackpots_changes", this.updateJackpotItemInList);
        this.$bus.$on("websocket.allsecure_notification", this.logAllSecureNotification);
        this.$bus.$on("websocket.jackpot_win_award", this.jackpotUserWin);
        this.$bus.$on("websocket.balance", this.updateUserBalance);
        this.$bus.$on("websocket.payments_events", this.updatePlayerPaymentsHistory);
        this.emailConfirmation.addUserBus();
        this.$bus.$on("user.login", this.updateUrlPixelCkGRD);
        this.$bus.$on("user.logout", this.updateUrlPixelCkGRD);

        if (typeof front_api !== "undefined") {
            front_api.popups.jackpotUserWin = this.jackpotUserWin;
        }

        const titles = this.$tm(config.idlePageTitle.texts);
        IdleTitle.init(titles);

        useEscapePopupStrategy();
    },

    beforeUnmount() {
        this.$bus.$off("websocket.bonuses_changes", this.updateGifts);
        this.$bus.$off("websocket.freespins_changes", this.loadFSGiftsData);
        this.$bus.$off("websocket.comps_award", this.updateCompsAward);
        this.$bus.$off("websocket.payments_changes", this.userBalanceCommit);
        this.$bus.$off("websocket.tournaments_statuses", this.updateTournamentStatuses);
        this.$bus.$off("websocket.lootboxes_changes", this.updateLootboxList);
        this.$bus.$off("websocket.public:jackpots_changes", this.updateJackpotItemInList);
        this.$bus.$off("websocket.allsecure_notification", this.logAllSecureNotification);
        this.$bus.$off("websocket.jackpot_win_award", this.jackpotUserWin);
        this.$bus.$off("websocket.balance", this.updateUserBalance);
        this.$bus.$off("websocket.payments_events", this.updatePlayerPaymentsHistory);
        this.emailConfirmation.deleteUserBus();
        this.$bus.$off("user.login", this.updateUrlPixelCkGRD);
        this.$bus.$off("user.logout", this.updateUrlPixelCkGRD);
    },

    methods: {
        loadStartData,
        promiseAll,
        ...mapActions(useUserInfo, {
            updateUserProfile: "loadUserProfile",
            loadUserSettings: "loadUserSettings",
            initLoadUserData: "initLoadUserData",
        }),
        ...mapActions(useStatusCompPointsStore, {
            updateComp: "updateCompPoints",
        }),
        ...mapActions(useQuestStore, {
            updateStatusUserQuest: "updateStatusInQuest",
        }),
        ...mapActions(useTournamentsStore, {
            updateTournamentData: "updateUserTourStatuses",
        }),

        ...mapActions(useGiftsStore, [
            "loadGiftsData",
            "loadFSGiftsData",
        ]),

        ...mapActions(useJackpots, [
            "updateJackpotItemInList",
        ]),

        ...mapActions(useUserBalance, [
            "loadUserBalance",
            "updateUserBalance",
        ]),

        ...mapActions(useLootboxesStore, [ "updateLootboxList" ]),

        ...mapActions(useCommon, [
            "loadPlayerFieldsInfo",
        ]),

        ...mapActions(useQuestStore, [ "loadQuestsData" ]),
        ...mapActions(useTournamentsStore, [ "loadTournaments" ]),

        ...mapActions(useCommon, {
            loadCategoriesFileConfig: "loadCategoriesFileConfig",
        }),

        ...mapActions(useCashboxStore, [ "loadPlayerPaymentsHistory" ]),
        ...mapActions(useMultilang, [ "loadLocales" ]),

        ...mapActions(useGamesCommon, [ "loadGamesJackpots" ]),

        updateCompsAward({ data }) {
            const levelMaxXp = Number(this.currentLevel?.conditions?.[0].persistent_comp_points?.max);
            this.updateComp(data);
            if (data.persistent.points >= levelMaxXp) {
                this.updateUserProfile({ reload: true });
            }
        },

        userBalanceCommit(data) {
            log.info("WS_MESSAGE_PAYMENTS_CHANGES", { message: data });

            this.loadUserBalance();
        },

        updateGifts() {
            this.loadUserBalance();
            this.loadGiftsData();
        },

        updatePlayerPaymentsHistory() {
            this.loadPlayerPaymentsHistory({ reload: true });
        },

        updateTournamentStatuses({ data }) {
            // eslint-disable-next-line no-warning-comments
            const prepareData = Array.isArray(data) ? data : [ data ]; // TODO Softswiss returns an object instead of an array
            // delete after fix https://upstars.atlassian.net/browse/ALPA-1899
            this.updateTournamentData(prepareData);
            this.updateStatusUserQuest(prepareData);
        },

        async jackpotUserWin({ data }) {
            if (data.jackpot) {
                Modal.show({
                    name: "jackpot-win-popup",
                    component: jackpotWinPopup,
                    mobileFriendly: true,
                    hasOverflowVisible: true,
                    props: {
                        winJackpot: data,
                    },
                });
            }
        },

        logAllSecureNotification(data) {
            log.info("WS_MESSAGE_ALL_SECURE_NOTIFICATION", data);
        },
    },
});
</script>

<style lang="scss" src="../../ui/index.scss"></style>
