<template>
    <div
        :class="classesMain"
        class="jackpot-leaderboard"
    >
        <div
            v-if="isMobile"
            class="jackpot-leaderboard__wrapper--main jackpot-leaderboard__wrapper padding-s"
        >
            <FeText
                v-if="isMobile"
                :align="FeTextConfig.align.Center"
                :size="FeTextConfig.size.H2"
                class="jackpot-leaderboard__title"
            >
                {{ $t("JACKPOTS.LEADERBOARD.TITLES.OUR_JACKPOTS") }}
            </FeText>
            <FeTabs
                :show-slider="false"
                :tabs="tabs"
                class="jackpot-leaderboard__tabs"
                @set-active-tab="showTab"
            >
                <template #tab="slotScope">
                    <slot name="tab" v-bind="slotScope"/>
                </template>
            </FeTabs>
            <div class="jackpot-leaderboard__tabs-content">
                <template v-for="(block,index) in leaderBoardList">
                    <JackpotLeaderBoardBlock
                        v-if="index === 0 && activeTab === BIGGEST"
                        :key="block.title"
                        :block="block"
                    />
                    <JackpotLeaderBoardBlock
                        v-if="index === 1 && activeTab === LATEST"
                        :key="block.title"
                        :block="block"
                    />
                </template>
            </div>
        </div>
        <div
            v-else
            class="jackpot-leaderboard__wrapper--main jackpot-leaderboard__wrapper padding-s"
        >
            <JackpotLeaderBoardBlock
                v-for="block in leaderBoardList"
                :key="block.title"
                :block="block"
                class="jackpot-leaderboard__block"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from "@store/root";
import { storeToRefs } from "pinia";
import { computed, onMounted, onServerPrefetch, ref } from "vue";
import { useJackpotWinners } from "@store/jackpots/winnersJackpot";
import JackpotLeaderBoardBlock
    from "@components/JackpotsCustom/JackpotRow/JackpotLeaderBoard/JackpotLeaderBoardBlock.vue";
import FeText, * as FeTextConfig from "@ui/FeText/FeText.vue";
import FeTabs from "@ui/FeTabs/FeTabs.vue";
import { TabsType, TabType } from "@controllers/TabType";
import { BIGGEST, LATEST } from "@components/JackpotsCustom/JackpotRow/JackpotLeaderBoard/constants";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

interface Props {
    amountWinners?: number;
}

const props = withDefaults(defineProps<Props>(), {
    amountWinners: 5,
});
const store = useJackpotWinners();
const biggestTab = new TabType(BIGGEST, t("JACKPOTS.LEADERBOARD.TITLES.BIGGEST"));
const latestTab = new TabType(LATEST, t("JACKPOTS.LEADERBOARD.TITLES.LATEST"));
const activeTab = ref(BIGGEST);
const tabs = new TabsType("leaderboard", [ biggestTab, latestTab ], biggestTab);

onServerPrefetch(async () => {
    await store.getJackpotWinners();
});

onMounted(async () => {
    await store.getJackpotWinners();
});

const { isMobile } = storeToRefs(useRootStore());
const classesMain = computed(() => {
    return !isMobile.value ? "jackpot-leaderboard--with-bg" : "";
});
const biggestWinsList = computed(() => {
    return store.sortBiggestWinnersList.slice(0, props.amountWinners);
});
const latestWinsList = computed(() => {
    return store.sortLatestWinnersList.slice(0, props.amountWinners);
});
const leaderBoardList = computed(() => {
    return [
        {
            title: t("JACKPOTS.LEADERBOARD.TITLES.BIGGEST"),
            data: biggestWinsList.value,
        },
        {
            title: t("JACKPOTS.LEADERBOARD.TITLES.LATEST"),
            data: latestWinsList.value,
        },
    ];
});

function showTab(value) {
    activeTab.value = value.name;
}

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.jackpot-leaderboard {
    position: relative;
    display: grid;
    align-items: center;

    &__title {
        margin-bottom: 1rem;
    }

    &__tabs {
        margin-bottom: 1rem;
    }

    &__wrapper {
        display: grid;
        position: relative;
        background: var(--color-layer-alt-1);
        border-radius: $container--border-radius;
        z-index: 5;
        @include media(M) {
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;
            padding: 0.5rem 0;
            background: transparent;
        }
    }
}
</style>
