<script setup lang="ts">
import Tooltip from "@components/Tooltip/Tooltip.vue";
import astrocoinImage from "@modules/CompPoints/img/astrocoin.png";
import astrocoinImageRetina from "@modules/CompPoints/img/astrocoin@2x.png";
import { routeNames } from "@router/routeNames";
import { useStatusCompPointsStore } from "@store/compPoints/statusCompPointsStore";
import * as FeIconConfig from "@ui/FeIcon/FeIcon.vue";
import FeIcon from "@ui/FeIcon/FeIcon.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const router = useRouter();
const { getChargeableBalance } = storeToRefs(useStatusCompPointsStore());

function handleBalanceClick() {
    router.push({ name: routeNames.compPoints });
}
</script>

<template>
    <div
        class="d-flex justify-space-between astro-coin-balance align-items--center"
        @click="handleBalanceClick"
    >
        <div class="gap-column-xs align-items-center">
            <span v-t="'COMP_POINT.BANNER.MY_BALANCE'" class="font__caption-2--normal astro-coin-balance__title" />
            <Tooltip class="astro-coin-balance__tooltip text--align-center">
                <template #activator>
                    <div @click.stop>
                        <FeIcon :icon="FeIconConfig.icons.fill.info1" class="astro-coin-balance__icon-info"/>
                    </div>
                </template>
                <span v-t="'COMP_POINT.BALANCE_INFO_TOOLTIP'" />
            </Tooltip>
        </div>

        <div class="gap-column-xs align-items-center">
            <p class="font__caption-1">{{getChargeableBalance}}</p>
            <FeImage
                :src="astrocoinImage"
                :src-retina="astrocoinImageRetina"
                alt="astrocoin"
                class="astro-coin-balance__icon"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.astro-coin-balance {
    cursor: pointer;

    &__title {
        color: var(--color-text-body);
    }

    &__icon-info {
        display: flex;
    }

    &__icon-info, &__icon {
        width: 1rem;
        height: 1rem;
    }

    &__tooltip {
        position: relative;
    }
}
</style>
