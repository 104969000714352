import {
    ALLOW_DOMAINS,
    COUNTRY_BY_HOST,
    DEFAULT_LOCALE_BY_COUNTRY,
    EXCEPTION_DOMAIN_LANG_ALLOW,
    DOMAIN_FOR_AUSTRALIA,
} from "@theme/configs/constsLocales";

interface IAllDomainsHrefLangs {
    rel: string;
    hreflang: string;
    href: string;
}

export function metaHrefLangsLink(currentDomain: string, routePath: string): IAllDomainsHrefLangs[] {
    const DEFAULT_DOMAIN = DOMAIN_FOR_AUSTRALIA;
    const allDomainsHrefLangs: IAllDomainsHrefLangs[] = [];

    ALLOW_DOMAINS.forEach((allowDomain) => {
        if (currentDomain === allowDomain) {
            allDomainsHrefLangs.push({
                rel: "alternate",
                hreflang: DEFAULT_LOCALE_BY_COUNTRY[COUNTRY_BY_HOST[currentDomain]],
                href: `https://${ currentDomain }${ routePath }`,
            });

            if (EXCEPTION_DOMAIN_LANG_ALLOW[currentDomain]) {
                EXCEPTION_DOMAIN_LANG_ALLOW[currentDomain].forEach((local) => {
                    allDomainsHrefLangs.push({
                        rel: "alternate",
                        hreflang: local,
                        href: `https://${ currentDomain }/${ local }${ routePath }`,
                    });
                });
            }

            if (currentDomain === DEFAULT_DOMAIN) {
                allDomainsHrefLangs.push({
                    rel: "alternate",
                    hreflang: "x-default",
                    href: `https://${ currentDomain }${ routePath }`,
                });
            }
        }
    });

    return allDomainsHrefLangs;
}
