<template>
    <div class="bonus-page__slider gap-s padding-s">
        <i18n-t
            v-if="isLogged"
            tag="h3"
            class="bonus-page__slider-title"
            keypath="BONUS_PAGE.CASHBACK.USER_LEVEL"
        >
            <template #level>
                <span>{{ userLevelInfo.name }}</span>
            </template>
        </i18n-t>
        <p
            v-else
            v-t="'BONUS_PAGE.CASHBACK.LEVEL_TITLE'"
            class="bonus-page__slider-title h3"
        />
        <CarouselScroll class="bonus-page__carousel">
            <ProfileLevel
                v-for="item in levelsData"
                :key="item.id"
                :level="item"
                :active="true"
                :accent-level="item.id === userLevelInfo.id"
            />
        </CarouselScroll>


        <template v-if="isLogged">
            <div
                v-if="userLevelInfo.conditions"
                class="profile-levels__progress"
            >
                <ProfileLevelProgress/>
            </div>

        </template>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStatuses } from "@store/user/userStatuses";
import { useLevelsStore } from "@store/levels/levelsStore";
import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import ProfileLevel from "@modules/Profile/components/ProfileLevel/ProfileLevel.vue";
import ProfileLevelProgress from "@modules/Profile/components/ProfileLevelProgress/ProfileLevelProgress.vue";
import featureFlags from "@theme/configs/featureFlags";
import { useUserInfo } from "@store/user/userInfo";

export default {
    name: "LevelsListSlider",

    components: {
        ProfileLevel,
        ProfileLevelProgress,
        CarouselScroll,
    },

    data() {
        return {
            featureFlags,
        };
    },

    computed: {
        ...mapState(useUserStatuses, {
            userLevelInfo: "getUserLevelInfo",
        }),
        ...mapState(useLevelsStore, {
            levelsData: "getLevelsData",
        }),
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.bonus-page {
    &__slider {
        grid-template-columns: minmax(0, 1fr);

        &-title {
            color: var(--color-text-body);
            text-align: center;

            span {
                color: var(--color-primary-1);
            }
        }

        width: auto;
        padding: 1rem;
        box-sizing: border-box;
        align-self: center;
        background: var(--color-layer-alt-1);
        border-radius: var(--border-radius-s);
        overflow: hidden;

        .slider {
            box-sizing: border-box;
            padding: 2rem 4rem 3rem 4rem;

            &__item--second .profile-level__front {
                background: var(--color-layer-alt-4);
            }

            &__controls {
                display: none;
            }
        }
    }
}
</style>
