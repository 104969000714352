export interface IStringDictionary {
    [key: string]: string;
}

interface IBooleanDictionary {
    [key: string]: boolean;
}

export enum UNUSED_DOMAINS {
    "r19" = "rocketplay19.com",
    "r20" = "rocketplay20.com",
}

export enum DOMAINS_FOR_AUSTRALIA {
    "r1" = "rocketplay1.com",
    "r2" = "rocketplay2.com",
    "r3" = "rocketplay3.com",
    "r4" = "rocketplay4.com",
    "r5" = "rocketplay5.com",
    "r6" = "rocketplay6.com",
    "r7" = "rocketplay7.com",
    "r8" = "rocketplay8.com",
    "r9" = "rocketplay9.com",

    "r11" = "rocketplay11.com",
    "r12" = "rocketplay12.com",
    "r13" = "rocketplay13.com",
    "r14" = "rocketplay14.com",
    "r15" = "rocketplay15.com",
    "r16" = "rocketplay16.com",
    "r19" = "rocketplay19.com",
    "r22" = "rocketplay22.com",
}

export enum DOMAINS_FOR_CANADA {
    main = "rocketplay.com",
    "r10" = "rocketplay10.com",
    "r17" = "rocketplay17.com",
    "r18" = "rocketplay18.com",
    "r25" = "rocketplay25.com",
}

export const DOMAIN_FOR_CANADA = "rocketplay.com";
export const DOMAIN_FOR_AUSTRALIA_SPEC = DOMAINS_FOR_AUSTRALIA.r14;
export const DOMAIN_FOR_AUSTRALIA = DOMAINS_FOR_AUSTRALIA.r19;
export const DOMAIN_FOR_ITALY = "rocketplay77.com";

const LOCALES: IStringDictionary = {
    ENGLISH: "en",
    GERMANY: "de",
    AUSTRALIAN_ENGLISH: "en-AU",
    CANADIAN_ENGLISH: "en-CA",
    CANADIAN_FRANCH: "fr-CA",
    NEW_ZEALAND: "en-NZ",
    // IRELAND: "en-IE",
    // JAPAN: "ja",
    // BRAZILIAN_PORTUGUESE: "pt-BR",
    // SPANISH_MEXICAN: "es-MX",
    NORWEGIAN: "no",
    ITALIAN: "it",
    ESTONIAN: "et",
};

const COUNTRIES: IStringDictionary = {
    CANADA: "CA",
    AUSTRALIA: "AU",
    JAPAN: "JA",
    GERMANY: "DE",
    BRASILIA: "BR",
    NEW_ZEALAND: "NZ",
    ITALY: "IT",
    ESTONIA: "EE",
    AUSTRIA: "AT",
    NORWAY: "NO",
};

const ALLOW_DOMAINS: string[] = [
    DOMAIN_FOR_CANADA,
    DOMAIN_FOR_ITALY,
    DOMAIN_FOR_AUSTRALIA,
];

const REGIONS_BY_COUNTRIES = {};

const COMMON_CACHE_CONFIG = {};

// Prod
const COOKIE_BY_LOCALE: IStringDictionary = {
    [LOCALES.ENGLISH]: "ImVuIg==--cdf2295b990357560929af19e943a17b5e300f29",
    [LOCALES.GERMANY]: "ImRlIg==--e0cac7ed600e8e20136330bf90e2f6e073a92af5",
    [LOCALES.AUSTRALIAN_ENGLISH]: "ImVuLUFVIg==--fbd254fa568c10e641ca5c06ea17b19bfe67073c",
    [LOCALES.CANADIAN_ENGLISH]: "ImVuLUNBIg==--29d218f5f50d9be5f9eace2741de2f23166a8042",
    [LOCALES.CANADIAN_FRANCH]: "ImZyLUNBIg==--d8e86677e99dababc683627b504d0f1809a7d26f",
    [LOCALES.NEW_ZEALAND]: "ImVuLU5aIg==--db4ab3937ac2a25a3bde96826517feece7c2d2d3",
    [LOCALES.NORWEGIAN]: "Im5vIg==--23115d0eb47ba756c988d793797a7a11e5295d6d",
    [LOCALES.ITALIAN]: "Iml0Ig==--c25a46184c511929ff8a9d9e1d2ac1cbe4c674b1",
    [LOCALES.ESTONIAN]: "ImV0Ig==--6741c36ae03da7667902bfc28dae2b3f72a4cad8",
    // [LOCALES.IRELAND]: "ImVuLUlFIg==--3a15d0cea8cef7ae5655b5f7b1628d299ad50e4d",
    // [LOCALES.JAPAN]: "ImphIg==--415e361e5490129e3b4ced0e117175b2c1975335",
    // [LOCALES.BRAZILIAN_PORTUGUESE]: "InB0LUJSIg==--a80e1d55c81d204f67fa8e855240e179d9ef8606",
};

// // Stage
// const COOKIE_BY_LOCALE: IStringDictionary = {
//     [LOCALES.ENGLISH]: "ImVuIg==--2fe6ec9f3011901f7cfe4eecf1860f3ef40e2785",
//     [LOCALES.GERMANY]: "",
//     [LOCALES.AUSTRALIAN_ENGLISH]: "ImVuLUFVIg==--59a5c4efe7ba287962afe6e55c1018cc88f4f48d",
//     [LOCALES.CANADIAN_ENGLISH]: "ImVuLUNBIg==--d916ad335f8f6bc5b8f37b4e08383ab7e3eff1c7",
//     [LOCALES.NEW_ZEALAND]: "ImVuLU5aIg==--a3bd186f8af139e618d2ca4fecf7cb6c41b6ba2c",
//     [LOCALES.IRELAND]: "ImVuLUlFIg==--b5f53402f7c0fbb75fd4e8c6913c20a9d7aa9cbc",
// };

const COUNTRY_BY_HOST: IStringDictionary = {
    [DOMAIN_FOR_CANADA]: COUNTRIES.CANADA,
    ["develop." + DOMAIN_FOR_CANADA]: COUNTRIES.CANADA,
    ["local." + DOMAIN_FOR_CANADA]: COUNTRIES.CANADA,

    [DOMAIN_FOR_AUSTRALIA]: COUNTRIES.AUSTRALIA,
    ["develop." + DOMAIN_FOR_AUSTRALIA]: COUNTRIES.AUSTRALIA,
    ["local." + DOMAIN_FOR_AUSTRALIA]: COUNTRIES.AUSTRALIA,
    "rocketplay-de.com": COUNTRIES.GERMANY,
    "rocketplay-nz.com": COUNTRIES.NEW_ZEALAND,
    [DOMAIN_FOR_ITALY]: COUNTRIES.ITALY,
    "rocketplay22.com": COUNTRIES.AUSTRALIA,
};

const AVAILABLE_LOCALES: IBooleanDictionary = {
    [LOCALES.ENGLISH]: true,
    [LOCALES.GERMANY]: true,
    [LOCALES.AUSTRALIAN_ENGLISH]: true,
    [LOCALES.CANADIAN_ENGLISH]: true,
    [LOCALES.CANADIAN_FRANCH]: true,
    [LOCALES.NEW_ZEALAND]: true,
    // [LOCALES.IRELAND]: true,
    // [LOCALES.JAPAN]: true,
    // [LOCALES.BRAZILIAN_PORTUGUESE]: true,
    // [LOCALES.SPANISH_MEXICAN]: true,
    [LOCALES.NORWEGIAN]: true,
    [LOCALES.ITALIAN]: true,
    [LOCALES.ESTONIAN]: true,
};

const DEFAULT_LOCALE_BY_COUNTRY: IStringDictionary = {
    default: LOCALES.CANADIAN_ENGLISH,
    [COUNTRIES.CANADA]: LOCALES.CANADIAN_ENGLISH,
    [COUNTRIES.AUSTRALIA]: LOCALES.AUSTRALIAN_ENGLISH,
    [COUNTRIES.GERMANY]: LOCALES.GERMANY,
    // [COUNTRIES.BRASILIA]: LOCALES.BRAZILIAN_PORTUGUESE,
    [COUNTRIES.NEW_ZEALAND]: LOCALES.NEW_ZEALAND,
    [COUNTRIES.ITALY]: LOCALES.ITALIAN,
    [COUNTRIES.ESTONIA]: LOCALES.ESTONIAN,
};

const DEFAULT_COUNTRY = COUNTRIES.CANADA;

// @TODO https://upstars.atlassian.net/browse/ALPA-2695
const LOCALES_CLOSED_FOR_BOT = {
    [LOCALES.ENGLISH]: true,
    [LOCALES.GERMANY]: true,
    // [LOCALES.IRELAND]: true,
};

const EXCEPTION_DOMAIN_LANG_ALLOW = {
    [DOMAIN_FOR_CANADA]: [ LOCALES.GERMANY ], // for production
    ["develop." + DOMAIN_FOR_CANADA]: [ LOCALES.GERMANY ], // for QA test on RC stage
    ["local." + DOMAIN_FOR_CANADA]: [ LOCALES.GERMANY ], // for local test
};

const PATH_WITH_LANG_REDIRECT: IBooleanDictionary = {
    "/registration": true,
    "/sport/registration": true,
};

export {
    LOCALES,
    COUNTRIES,
    COOKIE_BY_LOCALE,
    COUNTRY_BY_HOST,
    AVAILABLE_LOCALES,
    DEFAULT_LOCALE_BY_COUNTRY,
    ALLOW_DOMAINS,
    DEFAULT_COUNTRY,
    EXCEPTION_DOMAIN_LANG_ALLOW,
    REGIONS_BY_COUNTRIES,
    PATH_WITH_LANG_REDIRECT,
    COMMON_CACHE_CONFIG,
};
