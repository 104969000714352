const STATUS = {
    RECEIVED: "received",
    ACTIVATED: "activated",
    EXPIRED: "expired",
    USED: "used",
    ERASED_BY_WITHDRAW: "erased_by_withdraw",
};

const TYPES = {
    Cashback: "Cashback",
    Bonus: "Deposit bonus",
    Cash: "Cash",
    Spin: "Giftspin",
    Group: "Group",
    BooongoFreebet: "Booongo Freebet",
    Custom: "Custom",
    BooongoFeature: "Booongo Feature Freespins",
    PlaysonFeature: "Playson Feature Trigger",
    XP: "XP",
    SoftGaming: "SoftGaming Gift",
    FreeSpins: "NonDeposit",
};

export const TYPE_GIFT_BONUS = "bonus";
export const TYPE_GIFT_DEPOSIT = "deposit";
export const TYPE_GIFT_REGISTRATION = "registration";
export const TYPE_GIFT_FS = "fs";

export const STATUSES_GIFT_CANCELED = "canceled";
export const STATUSES_GIFT_FINISHED = "finished";
export const STATUSES_GIFT_EXPIRED = "expired";
export const STATUSES_GIFT_WAGERDONE = "wager_done";
export const STATUSES_GIFT_LOST = "lost";
export const STATUSES_GIFT_HANDLEBETS = "handle_bets";

export const STATUSES_LOST_GIFT = [
    STATUSES_GIFT_FINISHED,
    STATUSES_GIFT_CANCELED,
    STATUSES_GIFT_EXPIRED,
    STATUSES_GIFT_WAGERDONE,
    STATUSES_GIFT_LOST,
];

export {
    STATUS,
    TYPES,
};
